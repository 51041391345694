.hero {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  background-color: var(--color-base-2);
  min-height: 550px;
  &.hero-gradient {
    background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  }
  &.hero-fullscreen-desktop {
    @include media-breakpoint-up(md) {
      height: 100vh;
    }
  }
  &.hero-fullscreen-mobile {
    @include media-breakpoint-down(md) {
      height: 100vh;
    }
  }
  .hero-background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 50%;
  }
  .container {
    z-index: 1;
  }
  .hero-text {
    flex: 0;
    margin: auto 0;
    h1 {
      margin-top: 50px;
      margin-bottom: 20px;
      color: inherit;
      font-weight: 600;
      font-size: 56px;
    }
    p {
      color: inherit;
      font-weight: 400;
      font-size: 26px;
      line-height: 1.4;
    }
    &.hero-text-invert {
      color: var(--color-base);
      html[data-mode="dark"] & {
        color: var(--color-base-900);
      }
    }
  }
  .hero-flair {
    margin-left: 10px;
    font-weight: 500;
    font-family: $font-family-monospace;
    font-size: 20px;
    color: $primary;
  }
  .hero-image {
    img {
      max-width: 100%;
    }
  }
  .hero-app-store {
    margin-top: 20px;
    .btn {
      margin-top: 10px;
      @include media-breakpoint-down(sm) {
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
      }
    }
  }
  .hero-buttons {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    bottom: 0;
    .btn {
      margin-top: 10px;
      @include media-breakpoint-down(sm) {
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
      }
    }
  }
}
