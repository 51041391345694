.bottom {
  background-color: var(--color-base-bg-2);
  color: var(--color-base-text);
  padding-top: $section-gutter / 2;
  padding-bottom: $section-gutter / 2;
  font-size: 13px;
  .copyright {
    min-height: 48px;
    line-height: 48px;
    padding: 3px 0;
    @include media-breakpoint-up(md) {
      display: inline-block;
      padding: 0;
    }
  }
  .menu-bottom {
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        display: block;
        @include media-breakpoint-up(md) {
          display: inline-block;
        }
        a {
          min-height: 48px;
          line-height: 48px;
          display: inline-block;
          color: inherit;
          text-decoration: underline;
          padding: 3px 0;
          @include media-breakpoint-up(md) {
            padding: 6px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
