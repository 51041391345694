// Lightmode colors
$primary: #2b3af7;
$primary-2: #424ff8;
$primary-text: #fdfcf9;
$secondary: #702bf7;
$base-bg: #ffffff;
$base-bg-2: #f8f9ff;
$base-bg-3: #eef2f9;
$base-text: #f5f5ec;
$base-text-2: #b6c3d6;
$logo-text:  #111827;

// Darkmode colors
$primary-dark: #4486f2;
$primary-2-dark: #2f74e5;
$primary-text-dark: #fdfcf9;
$secondary-dark: #8215cb;
$base-bg-dark: #0f0f10;
$base-bg-2-dark: #1a191c;
$base-bg-3-dark: #222124;
$base-text-dark: #f5f5ec;
$base-text-2-dark: #b6c3d6;
$logo-text-dark: #F4F4F5;

$zs-colors-light: (
    "primary": $primary,
    "primary-2": $primary-2,
    "primary-text": $primary-text,
    'secondary': $secondary,
    "base-bg": $base-bg,
    "base-bg-2": $base-bg-2,
    "base-bg-3": $base-bg-3,
    "base-text": $base-text,
    "base-text-2": $base-text-2,
    "logo-text": $logo-text
);

$zs-colors-dark: (
    "primary": $primary-dark,
    "primary-2": $primary-2-dark,
    "primary-text": $primary-text-dark,
    'secondary': $secondary-dark,
    "base-bg": $base-bg-dark,
    "base-bg-2": $base-bg-2-dark,
    "base-bg-3": $base-bg-3-dark,
    "base-text": $base-text-dark,
    "base-text-2": $base-text-2-dark,
    "logo-text": $logo-text-dark
);

$zs-colors-light-css-vars: ();
@each $color, $value in $zs-colors-light {
  $zs-colors-light-css-vars: map-merge(
    $zs-colors-light-css-vars,
    (#{$color}: var(--color-#{$color}))
  )
}

$font-family-heading: Lato, -apple-system, serif;
$font-family-base: Source Sans Pro, -apple-system, "Helvetica Neue", Arial, sans-serif; 
$font-family-monospace: Fira Mono, SFMono-Regular, Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-logo: Lato, -apple-system, "Helvetica Neue", Arial, sans-serif;

:root{  
  @each $color, $value in $zs-colors-light {
    --color-#{$color}: #{$value};
  }
}

html[data-mode='dark'] {
  @each $color, $value in $zs-colors-dark {
      --color-#{$color}: #{$value};
  }
}

@import 'style';
