.dark-mode-switcher {
  display: inline-flex;
  input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .dark-mode-switch {
    border: 1px solid var(--color-base-bg-3);
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    position: relative;
    width: 55px;
    background-color: var(--color-base-bg-3);
    .ball {
      background-color: #000000;
      border-radius: 50%;
      position: absolute;
      top: 4px;
      left: 4px;
      height: 20px;
      width: 20px;
      transform: translateX(0);
    }
    svg {
        fill: #000000;
    }
    &.dark {
      .ball {
          background-color: #ffffff;
        transform: translateX(25px);
      }
      svg {
          fill: #ffffff;
      }
    }
    &.animated {
      .ball {
        transition: transform 0.2s ease-out;
      }
    }
  }
}
.header {
  .dark-mode-switcher {
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-flex;
    }
  }
}
