.partners {
  img {
    padding: 20px;
    filter: grayscale(100%);
    width: 180px;
    height: auto;
    @include media-breakpoint-up(md) {
        width: 180px;
        height: auto;
    }
    html[data-mode="dark"] & {
      filter: grayscale(100%) invert(100%);
    }
    &:hover {
        transition: all .2s ease-in-out;
        transform: scale(1.1);
        filter: none;
        html[data-mode="dark"] & {
            filter: none;
        }
    }
  }
}
