.footer {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
  
  .menu-footer {
    font-size: 16px;
    a {
      color: var(--color-primary-text);
    }
  }
}

.grid-footer {
  .grid-view-more-button {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-primary);
    font-family: $font-family-base;
  }
}
.grid-header {
  h2 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-base);
    font-family: $font-family-heading;
  }
}

.hero-buttons {
  position: relative;
}

.button {
  margin-bottom: 4px;
}

.bottom {
  background-color: var(--color-primary-2);
  color: var(--color-primary-text);
  font-size: 18px;
  a {
    padding: 6px;
  }
}

.page.page-home {
  .card-apps {
    padding: 25px;
    background-color: var(--color-base-bg-3);
  }
}

.center-container-items {
    margin-left: auto;
    margin-right: auto;
}

.feature {
   height: 140px;
   display: flex;
   align-items:center;
   .feature-text {
       margin-top: auto;
       margin-bottom: auto;
       padding-left: 20px;
   }
   .feature-icon {
     img {
       margin: 0;
       width: 100px;
       height: 100px;
     }
     html[data-mode="dark"] & {
       img {
          filter: invert(1);
       }
     }
   }
}

.image-gallery {
    ul {
        margin-left: -10vw;
          padding-left: 10vw;
          margin-right: -10vw;
          padding-right: 10vw;
          white-space:nowrap;
        overflow: scroll;
    }
    li {
        display:inline;
    }
}

.image-gallery-item {
    &:hover {
        transform: scale(1.02);
        transition: transform .3s; /* Animation */
    }
}

.image-title-text {
    img {
        max-width: 100px;
        height: auto; 
    }
    h2 {
        display: inline;
    }
    p {
        display: inline;
    }
    td {
        padding: 5px;
    }
}

.spacer {
  min-width: 2rem;
  width: 100%;
  flex-shrink: 2;
}

.image-circle-text {
    img {
        max-width: 200px;
        height: auto;
        border-radius: 50%;
        overflow: hidden;
    }
    h2 {
        display: inline;
    }
    p {
        display: inline;
    }
    td {
        padding: 5px;
    }
}

.whatsapp-float {
    position: fixed;
    bottom: 25px;
    left: 25px;
    padding: 15px 15px;
    text-decoration: none;
    z-index: 9999;
}
